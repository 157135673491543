import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Motion Motion Detection Sensors",
  "path": "/Products/IN-Motion/500/Aufnahmestationen/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Der IN-Motion 500 verfügt über eine Mikrowelle und einen passiven Infrarot-Bewegungsmelder. Es ist perfekt für die Outdoor-Überwachung geeignet.",
  "image": "../../P_SearchThumb_MotionDetector.png",
  "social": "/images/Search/P_SearchThumb_MotionDetector.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Motion_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-Motion 500 Motion Detection Sensors' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-Motion 500 comes with a microwave and a passive infrared motion sensor. It is the perfectly suited for outdoor detection.' image='/images/Search/P_SearchThumb_MotionDetector.png' twitter='/images/Search/P_SearchThumb_MotionDetector.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Motion/500/Recorder/' locationFR='/fr/Products/IN-Motion/500/Recorder/' crumbLabel="Recorder" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-motion-500",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-motion-500",
        "aria-label": "in motion 500 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion 500`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Diskutieren Sie diesen Artikel im `}<a parentName="p" {...{
          "href": "https://forum.instar.de/c/andere-produkte/7"
        }}>{`INSTAR Forum`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "externe-aufnahmegeräte",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#externe-aufnahmeger%C3%A4te",
        "aria-label": "externe aufnahmegeräte permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Externe Aufnahmegeräte`}</h2>
    <h3 {...{
      "id": "verwendung-des-externen-sensors-zum-auslösen-einer-alarmaufzeichnung",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#verwendung-des-externen-sensors-zum-ausl%C3%B6sen-einer-alarmaufzeichnung",
        "aria-label": "verwendung des externen sensors zum auslösen einer alarmaufzeichnung permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verwendung des externen Sensors zum Auslösen einer Alarmaufzeichnung`}</h3>
    <p>{`Wenn Sie einen externen Bewegungssensor verwenden, erzielen Sie bei Alarmaufzeichnungen ein viel besseres Ergebnis. Alle auf Software basierenden Bewegungserkennungen neigen zu falschen Warnungen aufgrund von Änderungen der Beleuchtung oder allgemeinen Wetterbedingungen wie Regen, Schnee oder Nebel. Überwachungslösungen, wie sie z.B., `}<a href="https://www.qnap.com/" target="_blank" rel="noopener noreferrer">{`QNAP`}</a>{` oder `}<a href="https://www.synology.com/" target="_blank" rel="noopener noreferrer">{`Synology`}</a>{`, bieten eine überlegene Software-basierte Bewegungserkennung. Sie können jedoch immer noch nicht die Genauigkeit eines Hardwaresensors erreichen, der im Einklang mit Ihrer Überwachungskamera arbeitet. Diese Systeme bieten daher die Möglichkeit, den externen Sensor als Alarmauslöser zu verwenden - anstatt sich auf ihre interne Erkennung zu verlassen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f1a37f35b88fc235a91009e4b72d5840/d4e7f/IN-Motion_QNAP.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABcElEQVQoz31STYsTQRCdX+zB7D8Q4t2rMoN42izqRTC5ePMuQsyuu24k2WT6e6Z6umemq6e7pRMUXMHHq3pVFK+oQxW27ymTzqFHZJRyxpWUSipoQUlpui7zN5qmAQBj7bkt3m+aqy/k7bq5F5ZxdiCUCsmVIow/1IQKQRgnNSUkc7/fHw5HQlluKS0omLtdTZRx6MXmln/fNve7drtvtjtzIMMDGVWLISFO6MM0RT9F74M/FYWxAxPNOAwpBHV9c/y6putvfHPd3v1AQhJnQeuUUoox/YOidx569CHPHPr1lu1qxVsju0FAz6HnjWECuNQO/WPzWeJpse306+Xx2aV9fqXnl2fCfAHzhZ4v9E/qH13wl9l08OJd/eSVv6jGWeVmlbs45Vnlnpbj7TH8z9xp+PCZvfzYVSsoV7pcQrmEaqXLFbz5NNRqSimGkDmFEEIs/qyJMWptpZCms4jonMuRc1bvEXEanT89RC5G538BaMjvlJp8qr8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1a37f35b88fc235a91009e4b72d5840/e4706/IN-Motion_QNAP.avif 230w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/d1af7/IN-Motion_QNAP.avif 460w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/7f308/IN-Motion_QNAP.avif 920w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/e1c99/IN-Motion_QNAP.avif 1380w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/d702f/IN-Motion_QNAP.avif 1416w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f1a37f35b88fc235a91009e4b72d5840/a0b58/IN-Motion_QNAP.webp 230w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/bc10c/IN-Motion_QNAP.webp 460w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/966d8/IN-Motion_QNAP.webp 920w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/445df/IN-Motion_QNAP.webp 1380w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/3437f/IN-Motion_QNAP.webp 1416w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1a37f35b88fc235a91009e4b72d5840/81c8e/IN-Motion_QNAP.png 230w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/08a84/IN-Motion_QNAP.png 460w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/c0255/IN-Motion_QNAP.png 920w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/b1001/IN-Motion_QNAP.png 1380w", "/en/static/f1a37f35b88fc235a91009e4b72d5840/d4e7f/IN-Motion_QNAP.png 1416w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f1a37f35b88fc235a91009e4b72d5840/c0255/IN-Motion_QNAP.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Aktivieren Sie dieses Kontrollkästchen, um einen Alarm auszulösen, wenn der Alarm in Ihrer Kamera ausgelöst wird. Verwenden Sie die erste Option, wenn Sie die interne Bewegungserkennung Ihrer Kamera mit dem externen Sensor koppeln möchten. Oder überprüfen Sie die zweite Zeile, um die Auslösung dem externen Bewegungsmelder zu überlassen.`}</p>
    <h3 {...{
      "id": "wie-kann-der-externe-auslöser-in-der-qnap-surveillance-station-aktiviert-werden",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wie-kann-der-externe-ausl%C3%B6ser-in-der-qnap-surveillance-station-aktiviert-werden",
        "aria-label": "wie kann der externe auslöser in der qnap surveillance station aktiviert werden permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wie kann der externe Auslöser in der QNAP Surveillance Station aktiviert werden?`}</h3>
    <p>{`Nach dem Hinzufügen der Kamera zur `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/QNAP/"
      }}>{`QNAP Surveillance Station`}</a>{`, gehen Sie einfach zu Event Management und prüfen Sie die gewünschte Option. Wenn Sie die interne Bewegungserkennung Ihrer Kamera deaktiviert haben und sich ausschließlich auf den externen Bewegungsmelder verlassen, wählen Sie das zweite Kontrollkästchen aus und lassen Sie die Surveillance Station den Status des Alarmeingangs Ihrer Kamera überwachen. Wenn ein Sensor die Erkennung Ihres Bewegungssensors auslöst, wird auf Ihrem QNAP NAS eine Alarmaufzeichnung ausgelöst.`}</p>
    <p>{`Die erste Option überwacht dagegen den internen Alarmstatus Ihrer INSTAR-Kamera und löst bei jeder Aktivierung der Kamera einen Alarm aus. Verwenden Sie diese Option, wenn Sie die interne Bewegungserkennung Ihrer Kamera mit einem externen Sensor gekoppelt haben! Auf diese Weise beginnt die Surveillance Station nur mit der Aufnahme, wenn sowohl Ihr IN-Motion Sensor als auch die softwarebasierte Erkennung Ihrer Kamera ausgelöst wird. Dieser Modus reduziert falsche Alarme erheblich!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      